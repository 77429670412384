/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { graphql } from 'gatsby';
import { Page } from '@bodiless/gatsby-theme-bodiless';
import { addClasses, Div, withDesign } from '@bodiless/fclasses';
import { withNodeKey } from '@bodiless/core';
import { flow } from 'lodash';
import Layout from '../../../../components/Layout';
import { FlowContainerDefault } from '../../../../components/FlowContainer';

const FCP = flow(
  withDesign({
    Wrapper: addClasses('lg:w-1/2 lg:m-0 justify-start flex-col'),
  }),
  withNodeKey('page'),
)(FlowContainerDefault);
const FCD = flow(
  withDesign({
    Wrapper: addClasses('lg:w-1/2 lg:m-0 lg:h-screen overflow-scroll'),
  }),
  withNodeKey('guild'),
)(FlowContainerDefault);
export default props => (
  <Page {...props}>
    <Layout>
      <Div className="flex flex-wrap justify-start">
        <FCP />
        <FCD />
      </Div>
    </Layout>
  </Page>
);

export const query = graphql`
  query($slug: String!) {
    ...PageQuery,
    ...SiteQuery
  }
`;
